@import "./modules/others/bootstrap";
@import "./modules/others/antd";
@import "../../node_modules/simplebar-react/dist/simplebar.min.css";
@import "./modules/common";
@import "./modules/spacing";
@import "./modules/icons";
@import "./modules/font";
@import "./modules/color";
@import "./modules/styleRtl";
@mixin Arrowstyle {
display: block;
height: 15px;
width: 15px;
background-size: 14px;
background-repeat: no-repeat;
}
.autocomplete-items div {
padding: 10px;
cursor: pointer;
background-color: #f3f3f3;
border-bottom: 1px solid #dbdbdb;
}
// .slick-track{ margin-left: 0; }
// Top Left Category Drawer
.categorySidebar {
.ant-menu-item,
.ant-menu-submenu-title,
.ant-menu-sub {
user-select: none;
}
.ant-drawer-wrapper-body {
overflow-y: hidden;
}
.ant-menu-inline .ant-menu-item::after {
border-right: 5px solid $primaryClr;
}
.ant-menu-sub {
.ant-menu-sub {
.ant-menu-item,
.ant-menu-submenu-title {
font-size: 0.875rem !important;
}
}
}
.ant-drawer-title {
font-family: "AvertaBold";
font-size: 1.125rem;
}
.ant-drawer-content-wrapper {
width: 100% !important;
max-width: 350px !important;
}
}
// Header style
.topNav {
.header {
max-height: 65px;
padding: 15px 0;
.logo {
width: 140px;
height: 40px;
overflow: hidden;
img {
object-fit: contain;
}
}
.profile img {
width: 59px;
height: 40px;
border-radius: 3px;
object-fit: cover;
}
}
.search {
align-items: center;
display: flex;
margin-left: 20px;
input {
border: none;
border-left: 1px solid #cdcdcd;
padding-left: 35px;
width: 100%;
// @include searchIcon();
background: url("../assets/icons/search_gray.png") no-repeat;
background-size: 15px;
background-position: 10px;
}
}
}
// Home Banner Images
.homeBanner {
.containerBanner {
position: relative;
// padding-top: 28.2%;
background-color: #cdcdcd;
width: 100%;
// height: 400px;
height: 385px;
@media (max-width: 1200px) {
height: 385px;
}
@media (max-width: 768px) {
height: 305px;
}
@media (max-width: 400px) {
width: 305px;
}
.bannerImg {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
background-color: black;
img {
// opacity: 0.9;
height: 100%;
object-fit: cover;
}
}
}
.bannerContent {
width: 100%;
height: 385px;
position: absolute;
display: table;
text-align: center;
top: 0;
color: $white;
.parent {
display: table-cell;
vertical-align: middle;
}
.title {
margin-bottom: 0 !important;
font-family: "AvertaBold";
font-size: 3.5em;
}
.subTitle {
font-size: 1.5rem;
font-family: "AvertaSemiBold";
}
}
.slick-dots {
bottom: 25px !important;
li {
margin: 0 2px;
button::before {
font-size: 10px;
color: $primaryClr;
}
&.slick-active button::before {
color: $primaryClr;
}
}
}
}
@media (max-width: 768px) {
.homeBanner .containerBanner .bannerImg img {
height: 100%;
object-fit: fill;
}
.homeBanner .containerBanner {
height: 140px !important;
width: 100% !important;
}
}
.slick-arrow.slick-disabled,
.slick-next::before,
.slick-prev::before {
display: none !important;
}
.slick-arrow.slick-prev {
left: -13px !important;
}
.slick-arrow.slick-next {
right: -13px !important;
}
.homeSlide {
.slick-track {
margin-left: 0px !important;
}
.slick-next,
.slick-prev {
position: absolute;
top: 37%;
transform: translate(0%, -37%) scale(1);
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
border-radius: 100%;
height: 32px;
width: 32px;
background-color: #fff !important;
opacity: 1;
transition: 0.2s ease;
z-index: 1;
display: flex;
justify-content: center;
align-items: center;
}
.nextArrow {
background-image: url("../assets/icons/right-arrow.png");
@include Arrowstyle();
background-position: 2px 0;
}
.prevArrow {
background-image: url("../assets/icons/left-arrow.png");
@include Arrowstyle();
background-position: -2px 0;
}
.categoryImg,
.serviceImg {
overflow: hidden;
border-radius: 5px;
img {
border-radius: 5px;
width: 100%;
object-fit: cover;
transition: 0.4s ease;
&:hover {
transform: scale(1.15);
}
}
}
}
.recentTasks {
.cardOneStyle {
padding: 15px;
border-radius: 6px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.detailTwo {
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
font-size: 14px;
min-height: 42px;
}
.slick-slide > div {
margin: 5px;
}
.slick-next,
.slick-prev {
// top: 28%;
// transform: translate(0%, 0%) scale(1);
// position: absolute;
// top: 50%;
// left: 100%;
// margin-right: -50%;
// transform: translate(-50%, -50%)
display: flex;
align-items: center;
margin: 10px 0;
}
}
.howDoesWork {
.col-lg-4 {
img {
height: 220px;
object-fit: cover;
cursor: pointer;
}
}
}
footer {
margin-top: 50px;
padding-top: 25px;
box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.1);
position: absolute;
width: 100%;
bottom: 0;
height: 60px;
.footerBtn {
display: flex;
justify-content: flex-end;
margin: 25px 0;
button {
box-shadow: 0px 1px 10px 0px #e3e3e3;
padding: 13px 25px;
border-radius: 2px;
display: flex;
align-items: center;
background: transparent;
border: none;
&:focus {
box-shadow: 0px 1px 10px 0px #e3e3e3 !important;
}
}
}
// .appLinkAndroid {
//     background: url("../assets/icons/social/appLinkAndroid.png") no-repeat 0 0/contain;
// }
// .appLinkIos {
//     background: url("../assets/icons/social/appLinkIos.png") no-repeat 0 0/contain;
// }
.appLinkSize {
height: 55px;
width: 120px;
border-radius: 8px;
cursor: pointer;
transition: 0.2s ease;
&:hover {
-webkit-transform: scale(1.04);
-ms-transform: scale(1.04);
transform: scale(1.04);
}
}
.ant-select-selector {
box-shadow: 0px 1px 10px 0px #e3e3e3;
border: none !important;
padding: 5px 15px !important;
height: auto !important;
width: 200px !important;
.ant-select-selection-item {
padding-right: 0 !important;
}
.ant-select-arrow {
display: none;
}
}
}
// .ant-select-dropdown {
//     width: 150px !important;
// }
.authenticateWidth {
max-width: 490px;
margin: 0 auto;
.ant-tabs-nav-operations.ant-tabs-nav-operations-hidden {
display: none;
}
}
.authenticateField {
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
.ant-tabs-nav {
width: 100%;
.ant-tabs-nav-list {
border-bottom: 1px solid #eee;
}
.ant-tabs-tab {
width: 50%;
text-align: center;
margin-right: 0 !important;
}
.ant-tabs-ink-bar {
top: 1px;
}
}
.socialIcon {
width: 45px;
height: 45px;
}
.ant-tabs-nav-operations,
.ant-tabs-dropdown {
display: none;
}
}
@media (max-width: 400px) {
.authenticateField .rememberMe {
font-size: 16px;
flex-direction: column;
div:first-child {
margin-bottom: 5px;
}
}
}
.profile-sm {
height: 40px;
width: 55px;
border-radius: 6px;
object-fit: cover;
}
// LoadMore
.loadMoreServices {
text-align: center;
background: $colorFour;
padding: 10px 0px;
margin: 30px 0px;
border-radius: 4px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
user-select: none;
&:hover,
&:active {
color: $colorOne;
svg {
animation: loadingCircle 2s infinite linear;
}
}
}
.loadMoreBtn {
background-color: #f0f0f0;
}
// Header
.topNav {
min-height: 64px;
display: flex;
align-items: center;
z-index: 2;
background: $colorSeven;
width: 100%;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
position: sticky;
top: 0;
}
.locationSearchTop {
display: flex;
position: relative;
align-items: center;
i {
svg {
color: $colorFive;
}
}
.locationSearcher {
border: none;
align-self: center;
padding: 0px 10px;
width: 400px;
@media (max-width: 991px) {
width: 100%;
padding: 5px 10px;
}
}
}
.ant-input {
&:hover,
&:focus {
border: 1px solid $colorFive;
box-shadow: none;
}
}
.ant-input-group-addon {
svg {
color: $colorFive;
}
}
// Top Left Category Drawer
.categorySidebar {
.ant-menu-item,
.ant-menu-submenu-title,
.ant-menu-sub {
user-select: none;
}
.ant-drawer-wrapper-body {
overflow-y: hidden;
}
.ant-menu-inline .ant-menu-item::after {
border-right: 5px solid $colorOne;
}
.ant-menu-sub {
.ant-menu-sub {
.ant-menu-item,
.ant-menu-submenu-title {
font-size: 16px !important;
}
}
}
.ant-drawer-title {
font-family: "AvertaSemiBold";
font-size: 18px;
}
.simplebar-wrapper {
height: 91vh;
overflow-y: auto;
overflow-x: hidden !important;
@media (max-width: 545px) {
height: unset;
}
}
.simplebar-track.simplebar-horizontal {
display: none;
}
}
.headerTitleSection {
padding: 20px;
font-size: 1.125em;
// font-family: $fM;
background: $colorNine;
z-index: 1;
}
.css-19bon8n {
line-height: unset !important;
}
.bookingStatusToggle {
padding-left: 10px;
&.pending {
color: red;
}
&.nonBooking {
color: blue;
}
&.onProcess {
color: red;
}
}
.coloredAntLookAlike {
display: flex;
align-items: center;
// background-color: $colorNine;
min-height: 40px;
padding: 6px 11px;
padding-left: 11px;
font-size: 16px;
border: 1px solid $colorSix;
border-radius: 6px;
}
.coloredAntNumberLookAlike {
border: 1px solid $colorSix;
border-radius: 6px;
width: 90px;
font-size: 16px;
background-color: $colorNine;
display: flex;
align-items: center;
min-height: 40px;
padding: 6px 11px;
}
.reviewModal {
max-width: 700px !important;
width: 100% !important;
padding-bottom: 0px;
.ant-modal-content {
margin: 10px;
.ant-modal-close {
top: 7px;
}
}
.ant-modal-body {
padding: 20px;
}
.overflowSection {
max-height: 300px;
height: 100%;
min-height: 300px;
overflow-x: hidden;
}
}
// RightSideBar Profile
.profileRightSideBar {
.ant-drawer-body {
padding: 0px;
font-size: 16px;
.contents {
.userNameHere {
font-size: 18px;
font-family: "AvertaSemiBold";
}
.userLocationHere {
font-size: 14px;
}
.menuSide {
.ant-menu {
.ant-menu-item {
span {
font-size: 16px;
}
}
}
.alertBadge {
background-color: $primaryClr;
color: #fff;
border-radius: 50%;
width: 30px;
height: 30px;
font-size: 12px !important;
display: flex;
align-items: center;
justify-content: center;
}
}
}
}
.ant-drawer-content-wrapper {
max-width: 300px;
width: 100% !important;
min-width: 300px;
}
}
.notificationRightBar {
.ant-drawer-content-wrapper {
max-width: 370px;
width: 100% !important;
// min-width: 300px;
// @media(max-width: 575px) {
//     max-width: 500px;
// }
.ant-drawer-wrapper-body {
overflow-y: hidden !important;
}
.ant-drawer-body {
padding: 0px;
}
}
.notificationFieldHolder {
.cardOneStyle {
border-bottom: 1px solid #dee2e6;
cursor: pointer;
&.new {
background-color: #f9f9f9;
.detailsSection {
.details {
.detailOne {
font-family: "AvertaSemiBold";
}
.detailTwo {
font-size: 14px;
font-family: "AvertaSemiBold";
}
}
}
.detailThree {
font-size: 14px;
font-family: "AvertaSemiBold";
}
}
.detailsSection {
.details {
.detailOne {
font-family: "AvertaSemiBold";
}
.detailTwo {
font-size: 14px;
}
}
}
.detailThree {
font-size: 14px;
}
}
}
.simplebar-wrapper {
height: 91vh;
overflow-y: auto;
overflow-x: hidden !important;
@media (max-width: 545px) {
height: unset;
}
}
}
// help
.help {
min-height: 70vh;
.ant-tabs-left-bar {
border-right: 1px solid $colorSeven;
.ant-tabs-tab {
margin-bottom: 0;
text-align: left;
}
.ant-tabs-nav-container {
width: 220px;
}
.ant-tabs-ink-bar {
background-color: transparent;
}
}
.ant-tabs-left-content {
border: none;
}
}
// Chat
.chatLayouter {
position: absolute;
bottom: 0;
top: 0;
left: 0;
right: 0;
.leftSide {
border-right: 1px solid #ececec;
// box-shadow: -4px 0 15px rgba(0, 0, 0, .15);
@media (max-width: 991px) {
box-shadow: none !important;
border: 1px solid #ececec;
}
.head {
border-bottom: 1px solid #eeeeee;
.locationSearchTop {
&:before {
content: none;
}
}
}
.chatUsers {
.cardOneStyle {
border-bottom: 1px solid #eeeeee;
cursor: pointer;
&.active {
background-color: $colorOne;
color: $colorSeven;
.details {
.detailTwo {
color: $colorSeven;
}
}
}
.details {
.detailTwo {
font-size: 14px;
color: $colorFive;
&.active {
color: $colorThree !important;
}
}
}
}
}
}
.rightSide {
// flex: 1 1 auto;
border: 1px solid #eeeeee;
.chatFooter {
height: 30px;
border-top: 1px solid #eeeeee;
}
.head {
height: 80px;
border-bottom: 1px solid #eeeeee;
.cardOneStyle {
.details {
.detailTwo {
font-size: 14px;
color: $colorFive;
}
}
}
}
}
}
.chatBoxInput {
display: flex;
align-items: center;
textarea {
width: 100%;
min-height: 45px;
max-height: 55px;
padding: 5px 10px;
border: 0;
resize: none;
// overflow: hidden;
}
}
.receivedSection {
max-width: 480px;
margin-bottom: 20px;
margin-right: auto;
.parent {
align-self: flex-start;
background-color: $white;
padding: 10px;
margin-bottom: 10px;
position: relative;
border-radius: 6px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
word-break: break-word;
.priceBox {
background-color: #f9fafb;
padding: 15px;
display: flex;
justify-content: space-between;
align-items: center;
}
.PayStatus {
display: flex;
button {
background: transparent;
border: none;
width: 50%;
padding: 13px 0;
}
}
}
&.withAcceptMessageEvent {
max-width: 650px;
}
}
.sendingSection {
// max-width: 480px;
max-width: 480px;
align-self: flex-end;
margin-bottom: 20px;
.parent {
background-color: #f9f1ef;
padding: 15px;
margin-bottom: 10px;
position: relative;
border-radius: 6px;
border-top-right-radius: 0px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
word-break: break-word;
.priceBox {
background-color: #f7d0c4;
padding: 15px;
display: flex;
justify-content: space-between;
align-items: center;
}
}
&::before {
content: "";
position: absolute;
left: 100%;
top: 0;
width: 0;
height: 0;
border-top: 0px solid transparent;
border-left: 10px solid $colorTwo;
border-bottom: 8px solid transparent;
}
&.withAcceptMessageEvent {
max-width: 650px;
}
}
.modal-center {
overflow-y: auto;
overflow-x: hidden;
height: 100%;
max-height: 395px;
}
.dateShower {
font-size: 14px;
color: $colorFive;
text-align: center;
margin-bottom: 15px;
}
.messageSendnRecievedImages {
height: 220px;
object-fit: contain;
}
.timeStamp {
font-size: 12px;
text-align: right;
margin-top: 5px;
}
.acceptMessageEvent {
.acceptMessageEventImg {
height: 135px;
width: 100%;
object-fit: cover;
}
.acceptMessageEventPrice {
font-size: 18px;
font-family: "AvertaSemiBold";
}
}
// Recent Tasks View All
.cardTwoStyle {
border: 1px solid #eeeeee;
padding: 20px;
margin-bottom: 15px;
border-radius: 6px;
a {
color: $colorThree !important;
}
.fullImg-sm {
width: 100%;
height: 100px;
object-fit: cover;
transition: 0.4s ease;
background-color: #eeeeee;
border-radius: 6px;
@media (max-width: 575px) {
height: auto;
}
}
.detailsSection {
.detailsOne {
font-family: "AvertaSemiBold";
margin-bottom: 10px;
}
.detailsTwo,
.detailsThree {
font-size: 14px;
margin-bottom: 10px;
display: flex;
}
.detailsFour {
font-family: "AvertaSemiBold";
}
.detailsFive {
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
}
.detailsSix {
font-size: 20px;
font-family: "AvertaSemiBold";
}
}
&:hover {
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
// cursor: pointer;
.successLight {
background: $colorEight !important;
color: #fff !important;
}
}
}
// Contact us
.contactUs {
.ant-form label,
textarea {
font-size: 16px;
margin-bottom: 0;
}
}
// User Edit Settings
.editSettingsRightBar {
.ant-drawer-content-wrapper {
max-width: 600px;
width: 100% !important;
min-width: 300px;
.ant-drawer-content {
.ant-drawer-body {
padding: 0px;
}
.ant-tabs-ink-bar {
bottom: unset;
top: 0;
}
}
}
}
.profile {
.ant-upload.ant-upload-select-picture-card {
width: 140px !important;
height: 80px;
.ant-upload {
padding: 0 !important;
img {
height: 100%;
object-fit: cover;
}
}
}
}
// Floating Label
.floatingLabelStyle {
.upLabel {
position: absolute;
top: 5px;
left: 0;
transition: 0.4s all ease;
pointer-events: none;
}
.upInputs {
margin-bottom: 2rem;
}
.upInputs input,
.upInputs textarea {
box-shadow: none;
box-shadow: none;
border: none;
border-bottom: 1px solid #dbdbdb;
width: 100%;
padding: 5px 0px;
border-radius: 0;
&:focus {
border-bottom: 1px solid $colorOne;
}
}
.upInputs input:focus~.upLabel,
.upInputs textarea:focus~.upLabel,
.upInputs input:not(:placeholder-shown)~.upLabel,
.upInputs textarea:not(:placeholder-shown)~.upLabel {
top: -20px;
border: none;
// text-transform: uppercase;
color: $colorFive;
}
}
.forms {
max-width: 600px;
// input {
//     padding-right: 30px !important;
// }
.ant-popover {
top: 269px;
width: 95%;
left: 11px;
right: 0;
}
.ant-popover {
margin: 0px auto;
width: 90%;
left: 0 !important;
right: 0 !important;
}
.ant-select-selection--multiple .ant-select-selection__placeholder,
.ant-select-selection--multiple .ant-select-selection__rendered {
margin-left: 0 !important;
}
.ant-select-selection__placeholder {
color: $colorThree !important;
}
}
.tick {
background-image: url("https://design.hitasoft.in/UI_team/surya/2.projects/onDemandIcons/tick.png");
height: 20px;
width: 20px;
display: inline-block;
background-repeat: no-repeat;
position: absolute;
right: 0;
bottom: 6px;
}
.error {
background-image: url("https://design.hitasoft.in/UI_team/surya/2.projects/onDemandIcons/error.png");
height: 20px;
width: 20px;
display: inline-block;
background-repeat: no-repeat;
}
.errorPos {
position: absolute;
right: 4px;
bottom: 3px;
}
.payOne {
.cardTwoStyle {
margin-bottom: 0px;
}
.ant-modal-header {
padding: 16px 24px 0px;
border-bottom: none;
}
.ant-modal-title {
font-family: "AvertaSemiBold";
font-size: 20px;
}
.ant-modal-body {
padding: 0.5rem;
.cardTwoStyle {
border: none !important;
box-shadow: none !important;
}
}
}
.payInput {
span {
background-color: $colorFour;
font-family: "AvertaSemiBold";
font-size: 20px;
width: 50px;
display: flex;
justify-content: center;
align-items: center;
}
input {
border: 1px solid colorSix !important;
border-radius: 0px;
}
}
// Tasker styles
@mixin card {
width: 15px;
height: 15px;
display: inline-block;
border-radius: 50px;
vertical-align: initial;
}
.taskerHome {
.box {
.my-card {
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
border-radius: 5px;
.red {
@include card;
border: 4px solid #ff0000;
}
.orange {
@include card;
border: 4px solid #ef902a;
}
.green {
@include card;
border: 4px solid #11a434;
}
.blue {
@include card;
border: 4px solid #026af5;
}
.yellow {
@include card;
border: 4px solid #d8d227;
}
}
}
.graph {
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
.tasks {
span {
background-image: linear-gradient(to right, #00a0ff, #01f1ff);
@include card;
}
}
.earn {
span {
background-image: linear-gradient(to right, #f14bd7, #ac0352);
@include card;
}
}
.recharts-wrapper {
margin: 50px auto 0 auto;
}
}
}
// Five column
.fiveColumn {
.col-lg-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 20%;
flex: 0 0 20%;
max-width: 20%;
}
@media (min-width: 320px) {
.col-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 99%;
}
}
@media (min-width: 576px) {
.col-sm-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%;
}
}
@media (min-width: 768px) {
.col-md-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 33%;
flex: 0 0 33%;
max-width: 33%;
}
}
@media (min-width: 992px) {
.col-lg-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 30%;
flex: 0 0 30%;
max-width: 30%;
}
}
@media (min-width: 1200px) {
.col-xl-5 {
-webkit-box-flex: 0;
-ms-flex: 0 0 20%;
flex: 0 0 20%;
max-width: 20%;
padding-right: 10px !important;
padding-left: 10px !important;
}
}
}
.forgotPopup {
left: 0 !important;
right: 0 !important;
margin: 0 auto;
max-width: 500px;
padding: 10px 30px;
}
// //
@mixin inputBorder {
box-shadow: none;
box-shadow: none !important;
border: none !important;
border-bottom: 1px solid #eeeeee !important;
width: 100%;
padding: 5px 0px;
}
.myInput {
@include inputBorder();
.ant-select-selection {
@include inputBorder();
}
}
.forgotPasswordTxt {
position: relative;
padding-left: 15px;
&:after {
content: "";
width: 1px;
height: 12px;
background-color: #529600;
left: 0;
position: absolute;
display: block;
top: 5px;
}
}
.addService {
position: absolute;
right: 0px;
top: 15px;
color: $colorOne;
z-index: 1;
@media (max-width: 575px) {
top: -30px;
}
}
.contactUs {
.form {
background-color: #f8f8f8;
}
}
.locationSearcher {
display: flex;
.myInput {
border: none;
border-bottom: 1px solid #eeeeee;
width: 100%;
flex: 0 0 auto;
padding-right: 15px;
padding-left: 10px;
border-radius: 0;
}
}
.serviceImageUploadField {
background-color: #eeeeee;
}
.viewAllzoomout {
height: 150px;
margin: 10px 0px;
object-fit: cover;
border-radius: 5px;
}
.viewAllZoomed {
height: auto;
object-fit: cover;
}
label input[type="file"] {
display: none;
}
.uploadFieldStructure {
border: 2px dashed $primaryClr;
height: 170px;
font-size: 55px;
display: flex;
justify-content: center;
align-items: center;
// margin: 10px 0px;
border-radius: 5px;
cursor: pointer;
p {
font-size: 16px;
width: 25%;
text-align: center;
font-family: "AvertaSemiBold";
padding: 10px;
span {
color: $primaryClr;
}
}
}
.map {
width: 100%;
height: 275px;
img {
height: 100%;
}
}
// .anticon-star svg {
//     fill: #ed8a19;
//     font-size: 16px;
//     margin-bottom: 5px;
// }
.coloredAntLookAlike {
position: relative;
.likeInput {
background-size: 18px;
background-position: 2px;
padding-left: 32px;
padding-right: 10px;
width: 20px;
height: 20px;
background-repeat: no-repeat;
&::before {
content: "";
background-color: #cecece;
height: 100%;
width: 0.5px;
display: inline-block;
position: absolute;
top: 0;
}
&.calendarIcon {
background-image: url("../assets/images/calender.png");
}
&.mapIcon {
background-image: url("../assets/images/location.png");
}
}
}
.quantity {
align-items: center;
justify-content: flex-end;
.decrement,
.increment {
// width: 35px;
height: 35px;
background-size: 21px;
background-color: transparent;
border: none;
background-repeat: no-repeat;
padding: 20px 0 20px 20px;
background-position: center;
}
.countBtn {
background: transparent;
border: unset;
padding: 5px 10px;
font-family: "AvertaSemiBold";
font-size: 18px;
width: 110px;
}
}
.headerTitleSection {
.links {
margin-right: 25px;
&:last-child {
margin-right: 0;
}
}
}
.innerContent {
// padding-top: 85px;
.d-flex.align-items-center {
position: relative;
overflow: hidden;
}
.technician {
.whatever {
background: url("https://design.hitasoft.in/UI_team/surya/2.projects/onDemandIcons/grid.png"),
no-repeat;
background-size: 20px;
width: 25px;
height: 27px;
background-position: 0 7px;
background-repeat: no-repeat;
cursor: pointer;
}
#checkboxes input[type="checkbox"] {
display: none;
}
#checkboxes input[type="checkbox"]:checked + .whatever {
background: url("https://design.hitasoft.in/UI_team/surya/2.projects/onDemandIcons/list.png"),
no-repeat;
background-size: 20px;
width: 25px;
height: 27px;
background-position: 0 7px;
background-repeat: no-repeat;
cursor: pointer;
}
.col-md-6.col-sm-12.col-12:nth-child(odd) {
padding-left: 0;
}
.col-md-6.col-sm-12.col-12:nth-child(even) {
padding-right: 0;
}
}
.image {
width: 100px;
margin: 0 auto;
}
.line {
padding: 10px 0;
.leftLine {
content: "";
width: 1px;
height: 75px;
background-color: #dee2e6;
transform: translate(10px, 0);
}
.rightLine {
content: "";
width: 100%;
height: 1px;
background-color: #dee2e6;
}
}
}
.innerPage {
padding: 20px;
background: #f8f8f8;
margin-bottom: 15px;
// position: fixed;
// width: 100%;
z-index: 1;
.react-tabs__tab-list {
border: none;
margin: 0;
.react-tabs__tab {
padding: 0 40px 0 0;
font-family: "AvertaSemiBold";
&:focus {
box-shadow: unset;
&::after {
background: transparent;
}
}
}
.react-tabs__tab--selected {
border: none;
background: transparent;
color: $primaryClr;
}
}
}
.taskerImages .slick-slide>div {
margin: 0px 3px;
}
.cardBox {
border: 1px solid #dee2e6;
.title {
background-color: #f8f8f8;
padding: 15px;
border-bottom: 1px solid #dee2e6;
text-align: center;
}
.content {
padding: 15px 20px 50px 20px;
}
}
.chatLayouter {
.head {
.searchIcon {
background: url("../assets/icons/search_gray.png") no-repeat;
background-size: 17px;
background-position: left;
height: 20px;
width: 30px;
flex: 0 0 auto;
}
}
.chatConversion {
background-color: #f8f8f8;
}
.typingArea {
background-color: $white;
.chatBoxInput {
padding: 15px;
textarea {
background-color: #f6f6f687;
}
// .PrimaryBtn {
//     padding: 7px 30px !important;
// }
}
}
}
.locationModal {
.ant-modal-body {
.google-map-code {
min-height: 300px;
max-height: 400px;
iframe {
width: 100%;
height: 50vh;
}
}
}
.searchIcon {
// @include searchIcon();
background: url("../assets/icons/search_gray.png") no-repeat center;
background-size: 18px;
width: 20px;
height: 20px;
flex: 0 0 auto;
}
}
.bookingTab,
.profileTab,
.myServices {
.ant-tabs-nav {
width: 100%;
.ant-tabs-nav-wrap {
// border-top: 1px solid #eee;
// border-bottom: 1px solid #eee;
background-color: #f9f9f9;
}
.ant-tabs-ink-bar {
// top: 0px !important;
display: none;
}
}
}
.rewardModal,
.quoteModal {
.dolorSign {
background-color: #eeee;
padding: 9px 15px;
position: absolute;
top: 0;
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
}
input {
padding-left: 50px !important;
border-radius: 4px;
box-shadow: none;
padding-right: 50px !important;
}
}
.singleStar {
margin-bottom: 5px;
}
.deActive {
width: 30%;
margin: 0 auto;
}
.profileForgotPwd {
width: 33%;
}
.tasker,
.user {
.ant-tabs-nav-list .ant-tabs-ink-bar {
background: transparent;
}
}
.taskerHome .ratingSection {
display: none !important;
}
.serviceDetail {
.bgBox {
padding: 25px;
background-color: #eee;
}
input[type="file"] {
display: none;
}
}
.ProPage {
.innerPage {
// background-color: $white;
padding-left: 0;
}
}
.rightSideBox .content div:last-child hr {
display: none;
}
.chatLayouter {
.onlineStatus {
width: 10px;
height: 10px;
border-radius: 5px;
position: absolute;
top: -3px;
right: -3px;
border: 2px solid #fff;
box-shadow: 0 2px 8px rgba(200, 200, 200, 0.1);
&.online {
background-color: $green;
}
&.away {
background-color: yellow;
}
}
.msgNotifi {
background-color: $primaryClr;
border-radius: 25px;
width: 20px;
height: 20px;
align-self: center;
flex: 0 0 auto;
color: #fff;
text-align: center;
font-size: 14px;
margin-left: 10px;
display: flex;
justify-content: center;
}
}
.profileUpload {
position: relative;
display: inline-block;
form {
width: 30px;
height: 30px;
position: absolute;
right: -13px;
bottom: -8px;
input {
opacity: 0;
position: absolute;
width: 100%;
cursor: pointer;
}
button {
border: none;
background: url("https://design.hitasoft.in/UI_team/surya/2.projects/onDemandIcons/edit_image.png")
no-repeat transparent center / 25px;
width: 25px;
height: 25px;
}
}
img {
width: 120px;
height: 80px;
object-fit: cover;
border-radius: 5px;
}
}
.editProfile {
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #eee;
}
.searchBox {
position: relative;
width: 250px;
.searchIcon {
position: absolute;
top: 11px;
left: 10px;
// @include searchIcon();
background: url("../assets/icons/search_gray.png") no-repeat center;
background-size: 15px;
width: 20px;
height: 20px;
}
input {
border: none;
background-color: #ececec87;
padding: 9px 9px 9px 40px;
border-radius: 5px;
width: 100%;
}
}
.ant-select .ant-select-selector {
padding: 4px 10px !important;
height: 40px !important;
border-radius: 3px !important;
}
.innerPage {
.d-flex {
a {
padding-right: 40px;
font-family: "AvertaSemiBold";
color: $txtClr;
&.active {
color: $primaryClr;
}
&:last-child {
padding-right: 0;
}
}
}
}
.innerContent .p-3 hr:last-child {
display: none;
}
.portfolio {
.box .row {
gap: 15px 0;
margin: 0 -30px;
}
img {
height: 135px;
border-radius: 3px;
cursor: pointer;
}
}
textarea.ant-input {
height: 100px;
resize: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
line-height: 30px !important;
}
.ant-picker-dropdown {
z-index: 1 !important;
}
// .ant-menu-submenu.ant-menu-submenu-open>div {
//     color: $primaryClr
// }
// .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
// .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
//     background: $primaryClr;
// }
.deActiveParent {
width: 100%;
height: calc(100vh - 64px);
position: absolute;
display: table;
text-align: center;
.text-center {
display: table-cell;
vertical-align: middle;
}
}
.ant-dropdown-menu-item .ant-btn {
background-color: transparent;
}
// .chatLayouter {
//     width: 100%;
//     padding-right: 0;
//     padding-left: 0;
//     margin-right: auto;
//     margin-left: auto;
// }
[data-rmiz-modal-content] .imgBg.rounded {
border-radius: unset !important;
}
footer .ant-select,
footer .ant-select-selector {
width: 100% !important;
}
.parent .google-map-code iframe {
width: 100%;
}
.paymentBox .content div:last-child hr {
margin-bottom: 0;
border: 0px solid;
}
.innerPage .d-flex.flex-wrap {
gap: 10px 0;
}
.ant-select-dropdown.ant-tree-select-dropdown {
z-index: 1 !important;
}
.chatConversion .requestBox {
width: 300px;
margin: 20px auto;
.parent {
padding: 15px;
border-radius: 5px;
&.sender {
background: #f9f1ef;
}
&.receiver {
background-color: #fff;
}
}
.Btns.success {
margin: 15px auto 0 auto;
display: flex;
}
}
.rightSide {
.head {
.backArrow {
background-image: url("../assets/icons/left-arrow.png");
background-size: 15px;
background-repeat: no-repeat;
width: 20px;
height: 20px;
}
}
}
.ant-modal-mask {
background-color: #1e1e1e !important;
opacity: 0.8 !important;
}
.embed-responsive {
height: 300px;
}
.howDoesWorkModal {
.ant-modal-close {
top: -12px !important;
right: -12px !important;
left: auto;
@media (max-width: 575px) {
right: -5px !important;
}
}
.ant-modal-close-x {
width: 30px !important;
height: 30px !important;
font-size: 16px !important;
line-height: 24px !important;
background-color: #fff !important;
border-radius: 50px !important;
display: flex !important;
align-items: center;
justify-content: center;
}
}
.authenticateWidth form .d-flex .ant-checkbox-wrapper {
display: flex;
}
.ant-select-selection-item-content .fM.primaryClr,
.ant-select-item-option-state {
display: none;
}
.ant-select-multiple .ant-select-selector {
border: 1px solid #d9d9d9 !important;
height: auto !important;
background-image: url("https://design.hitasoft.in/UI_team/surya/2.projects/onDemandIcons/down-arrow.png");
background-size: 10px;
background-repeat: no-repeat;
background-position: 98% center;
padding-right: 20px !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
color: $txtClr !important;
opacity: 1;
}
.ant-select-selection-search input {
border: none;
}
// .menuSide {
//     overflow: hidden;
//     .logoutBtn {
//         position: absolute;
//         bottom: 0;
//         width: 100%;
//     }
// }
.css-pp29ct {
width: 550px !important;
height: 300px !important;
object-fit: cover;
}
// .SRLThumbnailsContainer {
//     display: none !important;
// }
.SRLPrevButton,
.SRLNextButton {
background-color: #fff;
border-radius: 50px;
svg {
path {
fill: #000 !important;
}
}
}
.priceInput {
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
border-left: unset;
border-right: unset;
&:hover {
border-color: #dee2e6 !important;
}
}
}
.howDoesWork {
border-radius: 6px;
}
.paymentBox textarea {
border-bottom: 1px solid #dbdbdb;
border-radius: 0;
}
// Media Querys style
@media (max-width: 991px) {
.receivedSection,
.sendingSection {
max-width: 380px;
}
.uploadFieldStructure p {
width: 100%;
}
.howDoesWork .col-lg-4 iframe {
width: 100%;
height: 200px;
}
}
@media (max-width: 767px) {
.innerContent .image {
width: 80px;
}
.homeBanner {
.bannerContent {
.title {
font-size: 2.4em;
line-height: 50px;
margin-bottom: 10px !important;
}
.subTitle {
font-size: 1.3rem;
}
}
}
.col-md-4.col-12 .p-t85 {
padding-top: 40px !important;
}
.ant-upload-list.ant-upload-list-picture {
width: 100%;
}
.chatUsers {
margin-right: 15px;
}
.taskerHeader .search input {
border: none;
background-position: left !important;
padding-left: 30px;
}
.locationSearchTop .search input {
// background-position: 5px;
border: 1px solid aliceblue;
padding: 5px 5px 0px 30px;
border-radius: 5px;
height: 40px;
}
.technician .row .col-md-6.col-sm-12.col-12 {
padding: 0 15px !important;
}
}
@media (max-width: 575px) {
// .innerContent {
//     padding-top: 115px;
// }
.innerPage {
// margin-bottom: 0;
.container {
padding-right: 0;
padding-left: 0;
}
.react-tabs__tab-list .react-tabs__tab {
padding: 0 20px 0 0;
// &:first-child {
//     padding: 0 20px 10px 0;
// }
// &:last-child {
//     padding: 0 20px 0 0;
// }
}
}
.browseTask {
.sortBy,
.ant-select {
width: 100% !important;
}
}
.topNav .header .logo {
width: 125px;
}
.coloredAntNumberLookAlike {
width: 100%;
}
.categorySidebar [data-simplebar] {
height: 80vh !important;
}
.serviceDetail {
.PrimaryBtn {
width: 100%;
}
}
.ProPage .p-t85 {
padding-top: 30px !important;
}
.searchBox {
width: 100%;
margin-top: 15px;
}
.receivedSection,
.sendingSection {
max-width: 300px;
}
.howDoesWork .PrimaryBtn {
width: 100%;
}
// .ProPage .innerContent {
//     padding-top: 70px;
// }
.ant-tabs-nav .ant-tabs-tab {
text-align: center;
width: 50%;
margin-right: 0 !important;
}
.ant-tabs-nav-operations,
.ant-tabs-dropdown {
display: none;
}
.ant-tabs-nav-wrap {
margin-bottom: 15px;
}
// .chatLayouter .simplebar-scrollable-y {
//     height: unset !important;
// }
// .menuSide [data-simplebar] {
//     height: calc(-150px + 100vh) !important;
// }
// .editSettingsRightBar [data-simplebar] {
//     height: 100% !important;
// }
.portfolio .fiveColumn .col-xl-5 {
padding: 0;
}
.editSettingsRightBar .simplebar-scrollable-y {
height: calc(100vh - 152px) !important;
}
.contactUs .form button {
width: 100%;
}
footer {
padding-right: 15px;
padding-left: 15px;
.container {
padding: 0;
}
}
.mapInput.d-flex {
flex-direction: column;
button {
width: 100%;
margin-top: 15px;
}
}
.chatLayouter .typingArea .chatBoxInput {
// flex-wrap: wrap;
.fullWidth {
// flex: 0 0 100%;
// margin-top: 15px;
margin-left: 5px;
}
}
// .chatLayouter .leftSide .simplebar-scrollable-y,
// .chatLayouter .rightSide .chatConversion .simplebar-scrollable-y {
//     height: calc(-20px + 100vh) !important;
// }
.chatLayouter .rightSide .chatConversion .simplebar-scrollable-y {
height: calc(-350px + 100vh) !important;
}
.rightSideBox .content .quantity {
justify-content: flex-start;
margin-top: 10px;
}
.innerContent .technician .d-flex.align-items-center {
justify-content: space-between !important;
}
.rightSideBox .content .quantity {
padding-left: 70px;
}
.css-pp29ct {
width: auto !important;
height: auto !important;
}
}
@media (min-width: 575px) {
::-webkit-scrollbar {
width: 8px;
}
::-webkit-scrollbar-track {
background: rgb(238, 238, 238);
}
::-webkit-scrollbar-thumb {
background: rgba(0, 0, 0, 0.25) !important;
}
}
@media (min-width: 1200px) {
.row .col-xl-5:first-child {
padding-left: 0 !important;
}
.row .col-xl-5:last-child {
padding-right: 0 !important;
}
}
@media (min-width: 576px) and (max-width: 1200px) {
.row .col-xl-5 {
padding-left: 0;
}
}
// .taskerHome .title {
//     margin-left: 1rem;
// }
@media (max-width: 576px) {
.profileUpload {
width: 85%;
margin: 0 auto;
display: flex;
img {
width: 100%;
height: 120px;
}
}
.forgotPasswordTxt {
// padding-left: 0 !important;
&::after {
background: transparent;
}
}
.authenticateWidth h4 {
font-size: 20px;
}
.innerPage .d-flex a {
padding-right: 25px;
}
}
@media (max-width: 375px) {
footer .footerBtn button {
padding: 13px;
}
.editProfile {
flex-wrap: wrap;
.headerContent {
margin-bottom: 15px;
}
.change_pwd_div {
width: 100%;
button {
width: 100%;
}
}
}
.myService a,
.myService button {
width: 100%;
}
.chatConversion .requestBox {
width: 100%;
}
.innerPage .react-tabs__tab-list .react-tabs__tab {
padding: 0 15px 0 0;
}
}
@media (max-width: 345px) {
.ant-tabs-content form .d-flex.justify-content-center {
flex-direction: column;
.forgotPasswordTxt::after {
content: unset !important;
}
}
}
.ant-drawer ul .ant-rate-star {
cursor: pointer !important;
}
.ant-drawer-wrapper-body .RightArrow {
background: url("../assets/icons/right-arrow.png");
background-size: 17px;
width: 20px;
height: 20px;
background-repeat: no-repeat;
background-position: center;
}
#upload-portfolio {
opacity: 0;
position: absolute;
z-index: -1;
}
.priceInput {
span {
position: absolute;
right: 1px;
background-color: #e1e1e1;
height: 39px;
padding: 10px 15px;
}
}
// 10.07.2021
.suggestions {
position: absolute;
background-color: #fff;
width: 100%;
top: 40px;
border-radius: 5px;
box-shadow: 2px 3px 3px 3px #ddd;
padding: 5px;
max-height: 230px;
overflow: auto;
scrollbar-width: thin;
max-width: 500px;
right: auto;
left: 0;
li {
> div {
padding: 5px;
&:hover {
color: $primaryClr;
background-color: #feede7;
}
}
}
img.imgBg {
width: 70px;
height: 50px;
border-radius: 5px;
margin-right: 6px;
}
}
.ant-modal-body {
button.ant-btn-circle.ant-btn-icon-only {
display: flex;
align-items: center;
justify-content: center;
position: absolute;
bottom: 140px;
left: 40px;
span.anticon.anticon-aim {
line-height: 0;
}
}
div > input.pac-target-input {
border: none;
border-bottom: 1px solid #cdcdcd;
border-radius: 0px;
}
}
.locationModal .ant-modal-body {
margin-bottom: 40px;
}
.hoverBg:hover {
background: #feede7;
padding: 10px;
border-radius: 5px;
cursor: pointer;
}
// 15 july sj
.emptydetail {
img {
height: 350px;
width: 350px !important;
@media (max-width: 400px) {
height: 300px;
width: 350px !important;
}
}
}
.blocker-div {
display: grid;
place-items: center;
background-color: #000000a1;
color: #fff;
height: 50px;
padding: 0;
border-radius: 10px 10px 0px 0px;
width: 100%;
cursor: not-allowed;
}
.contact-block-div {
display: grid;
place-items: center;
background-color: #198BCA;
color: #fff;
height: 50px;
padding: 0;
border-radius: 10px;
width: 80%;
margin: 0 auto;
cursor: pointer;
}
.hover-cont {
color: $txtClr;
&:hover {
color: $primaryClr;
cursor: pointer;
}
}
.ant-rate-star-full svg {
fill: #ed8a19;
font-size: 16px;
margin-bottom: 0px;
}
.ant-rate-star-half .ant-rate-star-first svg {
fill: #ed8a19;
font-size: 16px;
margin-bottom: 0px;
}
.ant-rate-star-zero svg {
font-size: 16px !important;
}
.img-uploads {
position: relative;
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
// height: 200px;
}
.loc-loader {
position: absolute;
top: 0;
left: auto;
right: 0;
bottom: 0;
}
.white-spaces {
white-space: pre-wrap;
}
.center_pos {
min-height: calc(100vh - 250px);
position: relative;
> div {
padding-bottom: 100px;
}
}
.loadercls {
display: grid;
place-items: center;
height: 50vh;
}
.centerloader {
display: flex;
align-items: center;
justify-content: center;
width: 100%;
// height: 50vh;
}
.ant-select-show-search svg {
display: flex !important;
}
// .anticon .anticon-delete svg {
//     color: #000;
// }
button.ant-btn.ant-upload-list-item-card-actions-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only {
background: none;
margin: 0px 5px;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:before {
background-color: rgba(0, 0, 0, 0.7);
content: " ";
}
.ant-upload-list-item-info span {
height: 100%;
}
.h-300 {
height: 300px;
}
.h-330 {
height: 330px;
}
.chat_image {
height: 215px;
width: 100%;
max-width: 350px;
overflow: hidden;
object-fit: contain;
}
.paymentBox .content {
max-height: 330px;
overflow: auto;
@media (max-width: 400px) {
font-size: 14px;
}
}
#locationautocomplete-list {
position: absolute;
width: 100%;
z-index: 10;
display: block;
max-height: 220px;
overflow: auto;
box-shadow: 0px 2px 2px #333;
}
#manual_location1autocomplete-list {
@extend #locationautocomplete-list;
}
#manual_locationautocomplete-list {
@extend #locationautocomplete-list;
}
.rightsidebox_content {
height: 100%;
max-height: 260px;
overflow: auto;
}
.active_chat {
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
color: $primaryClr;
}
.ckeditor_cont_div {
flex-wrap: wrap;
word-break: break-word;
p {
margin-bottom: 0px;
}
}
img.notfi_placelogo-cls {
object-fit: contain;
height: 130px;
width: 200px;
}
@media (max-width: 576px) {
.resp-font24 {
font-size: 24px;
word-break: break-word;
}
}
// .profileUpload .anticon.anticon-edit svg {
//     // position: absolute;
//     // top: 0;
//     // left: 0;
//     transform: translate(0px, -4px);
// }
.ant-upload-list-picture .ant-upload-list-item-name {
width: 100%;
max-width: 400px;
}
.chatLayouter {
@media (max-width: 768px) {
.rightSide {
position: absolute;
top: 80px;
background-color: #fff;
left: 0px;
height: calc(100vh - 170px);
}
.moveLeft {
transform: translate(-200%);
transition: 1s;
}
.moveRight {
transform: translate(0%);
}
}
}
.detailOne {
@media (max-width: 480px) {
width: 100%;
max-width: 130px;
}
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
padding-top: 4px;
}
@media (max-width: 400px) {
.btn_cont span {
width: 100%;
max-width: 121px;
text-overflow: ellipsis;
overflow: hidden;
margin-top: 4px;
}
}
.swal2-styled {
padding: 0.25em 1.1em;
}
.ant-select-arrow {
background-image: url("../assets/icons/down-arrow.png");
background-size: 10px;
background-repeat: no-repeat;
width: 12px;
height: 12px;
background-position: center;
}
.downIcon {
background-image: url("../assets/icons/down-arrow.png");
background-position: center;
background-size: 10px;
background-repeat: no-repeat;
width: 15px;
height: 15px;
}
.map_tracker {
@media (max-width: 992px) {
margin: 50px 0px 0px 0px !important;
}
img {
object-fit: contain !important;
}
}
.where_div > span:first-child {
margin-bottom: 1rem;
}
.backbtn {
position: absolute;
top: -40px;
bottom: auto;
color: #fff !important;
left: 0;
width: 100%;
right: 0;
transform: translate(50px, 0px);
max-width: 96%;
@media (max-width: 992px) {
left: 20px;
right: 0;
transform: translate(0px, 0px);
}
}
.active_help {
color: $primaryClr !important;
}
.wid-sm-100 {
@media (max-width: 575px) {
width: 100% !important;
}
}
.changepasswdsidebar .ant-drawer-content-wrapper{
max-width: 600px;
width: 100% !important;
min-width: 300px;
}
.changepasswdsidebar .ant-drawer-content-wrapper .ant-drawer-body{
padding: 0px;
}
.quoteModal .ant-modal-header {
border: none;
display: flex;
}
.quoteModal .ant-modal-title {
font-family: 'AvertaSemiBold';
font-size: 22px;
}
.rewardModal .ant-modal-header {
border: none;
display: flex;
}
.rewardModal .ant-modal-title {
font-family: 'AvertaSemiBold';
font-size: 22px;
}
.tasker_booking .ant-modal-header {
border: none;
display: flex;
}
.tasker_booking .ant-modal-title {
font-family: 'AvertaSemiBold';
font-size: 22px;
}
.reviewModal .ant-modal-header {
border: none;
display: flex;
}
.reviewModal .ant-modal-title {
font-family: 'AvertaSemiBold';
font-size: 22px;
}
.otpmodal .ant-modal-header {
border: none;
display: flex;
}
.otpmodal .ant-modal-title {
font-family: 'AvertaSemiBold';
font-size: 22px;
}
.ltr {
direction: ltr;
}
//RTL css
.rtl {
direction: rtl;
}
.rtl .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
left: 20px !important;
right: auto;
}
.rtl .topNav .search input {
border-right: 1px solid #cdcdcd;
border-left: 0px solid;
padding-right: 35px;
background-image: url("../assets/icons/search_gray.png") no-repeat;
background-size: 15px;
background-position: 98%;
@media (max-width:768px) {
border: none;
}
}
.rtl .closeicon {
position: absolute;
left: auto;
right: 230px;
top: 20px;
bottom: auto;
}
.rtl .homeSlide {
.slick-track {
margin-right: 0px !important;
}
}
.rtl .ant-drawer-left.ant-drawer-open {
width: 100%;
transform: translate(91px) !important;
@media(min-width:1201px)and(max-width:1370px) {
transform: translate(174px) !important;
}
@media(min-width:993px)and(max-width:1200px) {
transform: translate(239px) !important;
}
@media(max-width:992px) {
transform: translate(150px) !important;
}
@media(max-width:768px) {
transform: translate(260px) !important;
}
@media(max-width:575px) {
transform: translate(269px) !important ;
}
@media(min-width:1700px)and(max-width:1920px) {
transform: translate(-101px) !important;
}
}
.rtl .profileRightSideBar .ant-drawer-mask {
position: absolute;
top: 0;
left: 100%;
}
.rtl .ant-drawer-left  .editSettingsRightBar .ant-drawer-content-wrapper {
max-width: 600px;
width: 100%;
min-width: 533px;
transform: translate(199px);
@media(min-width:1700px)and(max-width:1920px) {
transform: translate(396px);
}
@media(min-width:1201px)and(max-width:1370px) {
transform: translate(129px);
}
@media(min-width:993px)and(max-width:1200px) {
transform: translate(79px) ;
}
@media(max-width:992px) {
transform: translate(134px) ;
}
@media(max-width:768px) {
transform: translate(34px) ;
}
@media(max-width:481px) {
transform: translate(-79px);
min-width: 420px;
}
@media(max-width:360px) {
transform: translate(-200px);
min-width: 290px;
}
@media(max-width:330px) {
transform: translate(-241px);
min-width: 290px;
}
}
.rtl .ant-drawer-left  .notificationRightBar .ant-drawer-content-wrapper {
@media(max-width:481px) {
transform: translate(-79px);
min-width: 420px;
}
@media(max-width:400px) {
transform: translate(-191px);
min-width: 290px;
}
@media(max-width:330px) {
transform: translate(-241px);
min-width: 290px;
}
}
.rtl .floatingLabelStyle .upLabel {
position: absolute;
right: 0;
left: auto;
}
.rtl .forgotPasswordTxt:after {
content: "";
right: 0;
}
.rtl .forgotPasswordTxt {
position: relative;
padding-right: 15px;
}
.rtl .headerContent .closeBtn {
transform: rotate(180deg);
}
.rtl .ant-select-arrow {
position: absolute;
top: 50%;
left: 11px;
right: auto;
}
.rtl .ant-picker-input input {
padding: 0 45px 0 5px;
height: 30px;
}
.rtl a:hover {
color: $primaryClr !important;
text-decoration: none !important;
}
.rtl .breadcrumbs a .slashIcon {
transform: rotate(180deg);
}
.rtl .ant-form-item-label {
text-align: right;
}
.rtl .ant-input-group-addon:first-child {
border-right: 1px solid #dee2e6 !important;
border: 0px;
border-top: 1px solid #dee2e6 !important;
border-bottom: 1px solid #dee2e6 !important;
}
.rtl .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
border-top-left-radius: 0 !important;
border-bottom-left-radius: 0 !important;
}
.rtl .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
border-top-right-radius: 0;
border-bottom-right-radius: 0;
}
.rtl .ant-picker-suffix::before {
content: "";
right: 31px;
}
.rtl .ant-picker-rtl .ant-picker-suffix {
margin-right: 0;
}
.rtl .coloredAntLookAlike .likeInput {
background-size: 18px;
background-position: right;
padding-left: 0;
padding-right: 26px;
width: 32px;
height: 25px;
background-repeat: no-repeat;
}
.rtl .ant-modal-close{
width: 100% !important;
left: 0px;
right: auto;
.ant-modal-close-x {
width: 100%;
padding: 10px;
display: flex;
align-items: center;
justify-content: flex-end;
margin-right: -12px;
}
}
.rtl .howDoesWorkModal .ant-modal-close{
.ant-modal-close-x {
justify-content: center;
}
}
.rtl .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
padding-left: 18px;
padding-right: 0px;
}
.rtl .authenticateWidth .anticon svg {
transform: scale(-1);
}
.rtl .ant-drawer-close {
position: absolute;
top: 0;
left: 0;
right: auto;
}
.rtl .ant-modal-footer button + button {
margin-bottom: 0;
margin-right: 8px;
}
.rtl .receivedSection {
max-width: 480px;
margin-bottom: 20px;
margin-left: auto;
margin-right: 0px;
}
.rtl .homeSlide .slick-prev {
.prev-arrow{
transform: scale(-1) !important;
}
}
.rtl .homeSlide .slick-next{
.next-arrow{
transform: scale(-1) !important;
}
}
.rtl .innerPage .d-flex a {
padding-left: 40px !important;
padding-right: 0px;
}
.rtl .cardOneStyle .detailsSection {
padding: 0px 13px;
}
.rtl .breadcrumbs
{
+ div {
flex-direction: row-reverse;
.anticon {
line-height: normal;
}
}
}
@media (min-width: 1200px) {
.rtl .row .col-xl-5:first-child {
padding-right: 0 !important;
padding-left: 10px !important;
}
.rtl .row .col-xl-5:last-child {
padding-left: 0 !important;
padding-right: 10px !important;
}
}
.rtl .hover-cont{
flex-direction: row-reverse;
}
.rtl .ant-drawer-wrapper-body .RightArrow{
transform: scale(-1);
}
.rtl .PrimaryBtn {
background-color: #198BCA;
color: #fff;
font-size: 15px;
border: none;
padding: 8px 25px!important;
overflow: hidden;
position: relative;
transition: all .3s ease-in-out;
line-height: 23px;
margin-top: 0px !important;
}
.rtl .ant-drawer-left.ant-drawer-open {
-webkit-transform: translate(174px)!important;
transform: translate(202px)!important;
transition: none !important;
}
.rtl .ant-drawer-right, .ant-drawer-right .ant-drawer-content-wrapper {
right: initial;
left: 0;
transform: none !important;
}
// .rtl .ant-drawer-right, .ant-drawer-right .ant-drawer-content-wrapper {
//   transform: none !important;
// }
.rtl .PrimaryBtn:hover {
background-color: #feede7;
color: #198BCA;
}
.rtl .editSettingsRightBar .ant-drawer-content-wrapper {
max-width: auto !important;
width: 100% !important;
min-width: auto !important
}
.rtl .chatmargin{
margin: 20px;
}
.rtl .profileRightSideBar .ant-drawer-content-wrapper {
max-width: 300px !important;
width: 100% !important;
min-width: 300px !important;
}
.rtl .changepasswdsidebar{
background-color: white;
}
.rtl .changepasswdsidebar .ant-drawer-content-wrapper{
box-shadow: none !important;
}
.rtl .left-border{
background-color: #dbdbdb;
height: 1px;
width: 144vh;
}
.rtl .chpwdinput input{
border: none !important;
}
.rtl .changeedit{
border: none !important;
}
.rtl .changeborder{
background-color: #eee;
height: 1px;
width: 152vh;
}
.rtl .closeBtn{
transform: rotate(180deg);
}
.rtl .ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open{
transition: none !important;
}
.rtl .ant-drawer.ant-drawer-open .ant-drawer-mask{
animation:none !important;
transition: none !important;
}
.rtl.ant-drawer-mask{
animation:none !important;
transition: none !important;
}
.rtl .prfsidebar{
transition: none !important;
animation: none !important;
}
.rtl .ant-drawer-content-wrapper{
transition: none !important;
animation: none !important;
}
@media (max-width: 575px){
.rtl .ant-drawer-left.ant-drawer-open {
transform: translate(269px) !important;
}
.rtl .ant-drawer-left.ant-drawer-open.changepasswdsidebar  {
transform: translate(14px) !important;
}
}
.rtl .ant-drawer-left.ant-drawer-open.categorySidebar{
transform: none !important;
}
.rtl .categorySidebar .ant-drawer-content-wrapper {
transform: none !important;
}
.rtl .quoteModal .ant-modal-close-x{
padding-left: 20px;
}
.ltr  .ant-drawer-right .ant-drawer-content-wrapper {
right: 0;
left: initial;
transform: none !important;
}
.ltr .ant-drawer-right, .ant-drawer-right .ant-drawer-content-wrapper {
right: initial;
left: 0;
transform: translate(-22px) ;
transition: none !important;
animation: none !important;
}
.ltr .notificationRightBar .ant-drawer-content-wrapper{
transform: translate(22px) !important;
}
.ltr .ant-drawer-right.ant-drawer-open.editSettingsRightBar{
transform: none !important;
transition: none !important;
animation: none !important;
}
.ltr .profileRightSideBar{
transform: translate(0px) !important;
}
.ltr .modal_button{
text-align: left;
}
.ltr .ant-upload-list-picture-card-container{
float: left;
}
.rtl .ant-upload-list-picture-card-container{
float: right;
}
.rtl .myservice_input {
padding-right: 45px !important;
}
.rtl .quote_data {
text-align: right !important;
}
.rtl .quote_amountlabel {
text-align: right !important;
}
.rtl .map_text {
padding-right: 100px !important;
}
.rtl .trackIcon {
margin-right: 440px !important;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
margin-right: 0;
margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
right: 0;
left: auto;
}
.ant-rate-rtl {
direction: rtl;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
text-align: right;
}
.word-break {
word-break: break-word;
}
@media (max-width: 1024px) {
.homeBanner .containerBanner .bannerImg img {
object-fit: fill;
}
}
@media (min-width: 1920px) {
    .homeBanner .containerBanner .bannerImg img {
    object-fit: fill;
    }
    .homeBanner .containerBanner {
        height: 550px !important;
        }
    }
@media only screen and (max-width: 1500px) and (min-width: 2560px) {
.homeBanner .containerBanner .bannerImg img {
object-fit: fill;
}
.homeBanner .containerBanner {
height: 440px !important;
}
}
// .rtl .chart_tooltip{
//  background-color: #00a0ff;
// }
// .rtl .slick-slide{
//  direction: rtl;
// }
.rtl-chart {
direction: ltr !important;
}
.custom-tooltip {
margin: 0px;
padding: 10px;
background-color: rgb(255, 255, 255);
border: 1px solid rgb(204, 204, 204);
white-space: nowrap;
}
.custom-tooltip .label {
margin: 0px;
}
.custom-tooltip .intro {
margin: 0px;
display: block;
padding-top: 4px;
padding-bottom: 4px;
color: rgb(192, 90, 254);
}

// Update_261023
@media only screen and (min-width:320px) and (max-width: 375px) {
    .homeBanner .containerBanner{
        height: 100px !important;
    }
}
@media only screen and (min-width:425px) and (max-width: 767px) {
    .homeBanner .containerBanner{
        height: 140px !important;
    }
}
@media only screen and (min-width:768px) and (max-width: 768px) {
    .homeBanner .containerBanner{
        height: 200px !important;
    }
}
@media only screen and (min-width:991px) and (max-width: 1200px) {
    .homeBanner .containerBanner{
        height: 300px !important;
    }
}
// Update_261023